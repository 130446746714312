import React, { useState } from "react";

import {
  clientLogoDark75F,
  clientLogoEnrichAI,
  employerTestimonialOne,
  employerTestimonialTwo,
} from "@/constants/media";

import CurrentClientsCarousel from "@/components/landingPage/CurrentClientsCarousel";
import Footer from "@/components/landingPage/Footer";

import Testimonials from "../../components/landingPage/Testimonials";
import AnimationShowCase from "./components/AnimationShowCase";
import Banner from "./components/Banner";
import BookDemoBanner from "./components/BookDemoBanner";
// import DemoVideo from "./components/DemoVideo";
import FinalActionBar from "./components/FinalActionBar";
import HiringSteps from "./components/HiringSteps";

const Home = () => {
  const [
    showRestOfPageOnHiringAnimationComplete,
    setShowRestOfPageOnHiringAnimationComplete,
  ] = useState(false);

  return (
    <>
      <Banner />
      <CurrentClientsCarousel
        headingElement={
          <h1 className="text-lg text-center mb-[40px] lg:text-4xl md:text-2xl ">
            Trusted by <span className="text-gradient-1">Leading</span>{" "}
            Companies
          </h1>
        }
      />
      <HiringSteps
        setShowRestOfPageOnHiringAnimationComplete={
          setShowRestOfPageOnHiringAnimationComplete
        }
      />
      {showRestOfPageOnHiringAnimationComplete && (
        <>
          <AnimationShowCase />
          <BookDemoBanner />
          {/* <DemoVideo /> */}
          <Testimonials
            contents={[
              {
                personName: "Subhashini Ponappa",
                personTitle: "Head - Human Resource & Administration",
                image: employerTestimonialOne,
                employerLogo: clientLogoDark75F,
                description: `Zelevate's hiring platform quickly identified a pool of highly qualified candidates that matched our job requirements. Their   pre-interview screening process helped us save valuable time during the hiring process. Thanks to Zelevate, we found the perfect candidate for our engineering role in just a few weeks. Highly recommended!`,
              },
              {
                personName: "Arijit B",
                personTitle: "CEO",
                image: employerTestimonialTwo,
                employerLogo: clientLogoEnrichAI,
                description: `We've had a great experience using Zelevate. Their pre-interviewed and assessed pipeline has helped us bring in great operational efficiency in our hiring process. The assessments are on par to industry standards and we have been able to make quick hires for tech roles from their platform.`,
              },
            ]}
          />
          <FinalActionBar />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
