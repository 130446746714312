import React from "react";

import {
  employeranimationScrollFour,
  employeranimationScrollThree,
  employeranimationScrollTwo,
  employerScrollOne,
} from "@/constants/media";

import ScrollAnimation from "./EmployerScrollAnimation";

const AnimationShowCase = () => {
  return (
    <ScrollAnimation
      mainHeading={
        <h1 className="relative z-[1] w-2/3 mb-tm2 lg:w-full px-10 lg:px-20 lg:pt-20 pt-10">
          <span className="text-white">
            {" "}
            An Unrivalled Hiring Experience
            <br />
            With the
          </span>
          <span className="text-blue-highlight"> STEM Process</span>
        </h1>
      }
      contentOne={{
        heading: <>Screening Round</>,
        paragraph: (
          <>
            Forget resumes - We start by{" "}
            <span className="text-blue-highlight"> meticulously screening</span>{" "}
            seasoned developers for coding, communication, and collaboration
            skills Our candidates are screened through{" "}
            <span className="text-blue-highlight">50+ Questions </span> across{" "}
            <span className="text-blue-highlight">11 important Parameters</span>
          </>
        ),
        image: employerScrollOne,
        imageType: "image",
      }}
      contentTwo={{
        heading: <>Technical Interview</>,
        paragraph: (
          <>
            Our technical interviews aren&apos;t formalities - they&apos;re{" "}
            <span className="text-blue-highlight">deep dives</span> led by{" "}
            <span className="text-blue-highlight">industry experts.</span> We
            interview and analyze problem-solving, coding skills, and more to
            ensure you and your ideal developer are a{" "}
            <span className="text-blue-highlight">perfect match.</span>
          </>
        ),
        image: employeranimationScrollTwo,
        imageType: "image",
      }}
      contentThree={{
        heading: <>Evaluation Report</>,
        paragraph: (
          <>
            Uncover the hidden gems. Our evaluation reports go beyond the
            surface, analyzing screening and interview data to reveal a{" "}
            <span className="text-blue-highlight">
              Candidate&apos;s full potential.
            </span>{" "}
            Skills, preferences, it&apos;s all there, presented in a clear and
            concise way. Our reports present a complete{" "}
            <span className="text-blue-highlight">360&deg; Evaluation</span> of
            the Candidate&apos;s Skillset
          </>
        ),
        image: employeranimationScrollThree,
        imageType: "image",
      }}
      contentFour={{
        heading: <>Matching Engine</>,
        paragraph: (
          <>
            Our Matching Engine is like ChatGPT for hiring. Enter in your
            JD&apos;s and out pops the perfect candidate for you! Go beyond
            resumes with{" "}
            <span className="text-blue-highlight">
              AI-powered candidate profiles
            </span>{" "}
            built from our{" "}
            <span className="text-blue-highlight">in-depth reports.</span> We
            meticulously curate these profiles to exceed your expectations and
            ensure a perfect fit, not just in skills, but also in shared
            ambitions.
          </>
        ),
        image: employeranimationScrollFour,
        imageType: "image",
      }}
    />
  );
};

export default AnimationShowCase;
