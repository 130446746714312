import Script from "next/script";

import { Player } from "@lottiefiles/react-lottie-player";
import { RhCard, RhToggle } from "@rhythm-ui/react";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { animationStartHereArrow, vectorOfZelevate } from "@/constants/media";

const HiringSteps = ({
  setShowRestOfPageOnHiringAnimationComplete,
}: {
  setShowRestOfPageOnHiringAnimationComplete: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  const originalData = [
    {
      id: 1,
      title: " Sourcing of candidates ",
      isAdded: false,
      isHighlighted: false,
    },
    {
      id: 2,
      title: "Screening 100’s of resumes",
      isAdded: false,
      isHighlighted: false,
    },
    {
      id: 4,
      title: "Candidate skill validation calls",
      isAdded: false,
      isHighlighted: false,
    },
    {
      id: 3,
      title: "Scheduling interviews",
      isAdded: false,
      isHighlighted: false,
    },

    {
      id: 5,
      title: "Conducting initial interviews",
      isAdded: false,
      isHighlighted: false,
    },
    {
      id: 6,
      title: "Conduct Further Rounds", //Review Zelevate Interview Report and Conduct Further Rounds
      isAdded: false,
      isHighlighted: false,
    },
    {
      id: 7,
      title: "Send Offer Letter",
      isAdded: false,
      isHighlighted: false,
    },
  ];
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const firstAnimateDone = useRef(false);
  const [steps, setSteps] = useState(originalData);
  const mainDivEle = useRef(null);

  //start adding classes after some seconds
  const addClasses = () => {
    for (let i = 0; i < steps.length; i++) {
      setSteps((data) => {
        const steps = [...data];
        if (i == 5 || i == 6) {
          steps[i].isHighlighted = true;
          if (i === 5)
            steps[i].title =
              "Review Zelevate Report and Conduct Further Rounds";
        } else {
          steps[i].isAdded = true;
        }
        return steps;
      });
    }
  };

  const disableScrollOnElementView = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (firstAnimateDone.current) return;
        if (entry.isIntersecting) {
          setIsSwitchOn(true);
          addClasses();

          // in case the animation file fails to load, the page will be unscrollable.
          // in that case, need to revert overflowY property to scroll
          setTimeout(() => {
            setShowRestOfPageOnHiringAnimationComplete(true);
            firstAnimateDone.current = true;
          }, 2000);
        }
      });
    });

    if (disableScrollOnElementView.current) {
      observer.observe(disableScrollOnElementView.current);
    }

    return () => {
      if (disableScrollOnElementView.current) {
        observer.unobserve(disableScrollOnElementView.current);
      }
    };
  }, []);

  const onLottieComplete = () => {
    setShowRestOfPageOnHiringAnimationComplete(true);

    firstAnimateDone.current = true;
  };

  const onHiringTypeChangeHandler = () => {
    // in case the toggle switch does not switch automatically, which it should not happen,
    // we are expecting user to manually click on the toggle, which should allow them to see rest of the page
    setShowRestOfPageOnHiringAnimationComplete(true);
    firstAnimateDone.current = true;

    // if (isPresenting) return;
    setIsSwitchOn((state) => {
      //
      if (state) setSteps(originalData);
      else addClasses();
      return !state;
    });
  };

  return (
    <>
      <Script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></Script>
      <div
        className="relative p-5 pb-0 overflow-hidden md:p-10 lg:p-20 lg:pb-0 section-bg"
        ref={mainDivEle}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-tm3 lg:gap-0">
          <div className="relative flex flex-col gap-tm3">
            <div className="relative z-[1] mt-tm2 w-full lg:w-2/3 ">
              <h1 className="text-lg mb-tsm lg:mb-tm2 md:text-2xl lg:text-4xl">
                Redefining Hiring: Zelevate vs Traditional Hiring
              </h1>
              <p className="text-secondary-500">
                {`You shouldn't go through a long time-consuming process.`}
                <br />
                <strong>We do it for you!</strong>
              </p>

              <div className="flex gap-tsm mt-tm2 ">
                <RhToggle
                  onChange={onHiringTypeChangeHandler}
                  checked={isSwitchOn}
                ></RhToggle>{" "}
                <p>{isSwitchOn ? `Zelevate Hiring` : `Traditional Hiring`}</p>
              </div>
            </div>

            {isSwitchOn && (
              <>
                <div className="absolute hidden lg:block bottom-[90px] right-[-16px] h-[165px]">
                  <div className="absolute top-[30px] left-[-60px]">
                    <h2 className="font-semibold text-gradient-1">
                      Start Here!
                    </h2>
                  </div>
                  <Player
                    onEvent={(state) => {
                      if (state === "loop") onLottieComplete();
                    }}
                    background="transparent"
                    speed={1}
                    loop
                    autoplay
                    style={{ width: "220px" }}
                    src={animationStartHereArrow}
                    className="player"
                  />
                </div>
              </>
            )}
          </div>
          <div className="box-border z-[1] flex flex-col gap-3">
            {steps.map((data, idx) => {
              return (
                <RhCard
                  className={classNames("p-tsm relative overflow-visible ", {
                    isHighlighted: data.isHighlighted,
                  })}
                  key={idx}
                  ref={idx === 6 ? disableScrollOnElementView : undefined}
                >
                  <span
                    className={classNames("font-bold", {
                      strikethrough: data.isAdded,
                    })}
                  >
                    {data.title}
                  </span>
                  {idx === 5 && isSwitchOn && (
                    <>
                      {/* text-gradient-1  */}
                      <div className="block lg:hidden absolute -top-[30px] -right-[10px] text-xs p-1 shadow-md bg-white rounded-lg ">
                        <span className="font-light text-gradient-1">
                          Start Here!
                        </span>
                      </div>
                      <div className="block lg:hidden absolute -top-[30px] -right-[20px] ">
                        <Player
                          onEvent={(state) => {
                            if (state === "loop") onLottieComplete();
                          }}
                          background="transparent"
                          speed={1}
                          loop
                          autoplay
                          style={{ width: "100px" }}
                          src={animationStartHereArrow}
                          className="player rotate-[150deg]"
                        />
                      </div>
                    </>
                  )}
                </RhCard>
              );
            })}
          </div>
        </div>
        <img
          className="absolute -top-[100px] -left-[55px] opacity-25 lg:opacity-70 h-[200px]"
          src={vectorOfZelevate}
        />
        <img
          className="absolute -bottom-[95px] -right-[50px] z-0 opacity-25 lg:opacity-70"
          src={vectorOfZelevate}
        />
        <div className="mt-5 lg:pt-20"></div>
      </div>
    </>
  );
};

export default HiringSteps;
