import Link from "next/link";

import { RhButton, RhCard } from "@rhythm-ui/react";
import React from "react";

import { zelevateThemeBackgroundBanner } from "@/constants/media";

const BookDemoBanner = () => {
  return (
    <div className="flex items-center justify-center p-0 lg:mx-[80px] lg:mt-20">
      <RhCard className="flex flex-col relative items-center justify-center border-0 w-full py-10 lg:p-20 px-[40px] section-bg gap-tm2">
        <h2 className="text-center z-[2]">
          Hire smarter and faster from 10 Qualified Candidates, not thousands of
          Unverified Resumes
        </h2>

        <div className="flex flex-col items-center gap-tsm z-[2]">
          <p className="text-center">
            Start using Zelevate today and experience the difference. Hire like
            it’s 2024!
          </p>

          <div className="">
            <Link href={"/request-demo"} passHref>
              <RhButton className="btn-bg cursor-pointer">
                Book Demo Now!
              </RhButton>
            </Link>
          </div>
        </div>
        <img
          className="absolute top-0 right-[-30%] object-cover p-0 m-0 h-full w-full scale-125 z-[1]"
          src={zelevateThemeBackgroundBanner}
          alt=""
        />
      </RhCard>
    </div>
  );
};

export default BookDemoBanner;
