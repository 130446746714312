import { useRouter } from "next/router";

import { RhButton, RhInput } from "@rhythm-ui/react";
import React from "react";

import { LOCAL_STORAGE } from "@/constants";

const Footer = () => {
  const router = useRouter();
  const onEmployerEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = e.target.email.value;
    localStorage.setItem(LOCAL_STORAGE.EMPLOYER_SIGNUP_EMAIL, email);
    router.push("/request-demo");
  };

  return (
    <>
      {/* upper footer */}

      <div className="relative overflow-hidden px-tsm md:px-[40px] lg:px-20 py-10 md:py-[72px] bg-[#1B2434]">
        <div className="relative z-[1]  overflow-hidden ">
          <div className="relative grid justify-between grid-cols-1 md:grid-cols-2 gap-tsm md:gap-t3xl">
            <div className="flex flex-col gap-tsm">
              <h2 className="text-white">
                {/* <span className="text-white tex-2xl md:text-2xl lg:tex-4xl"> */}
                Ready to streamline your hiring process and find top talent?
                {/* {" "}
                  Discover the Power Of Zelevate and unlock an
                </span>{" "} */}
                {/* <span className="text-primary-500">Unmatched Hiring</span>{" "}
                <span className="text-primary-500">Experience</span>{" "}
                <span className="text-white">today!</span> */}
              </h2>
              <p className="text-white">
                {`Don't waste any more time sifting through resumes or conducting
                countless interviews. Join Zelevate today and let us handle the
                heavy lifting`}
              </p>
            </div>

            <form
              onSubmit={onEmployerEmailSubmit}
              className="flex flex-col items-center pl-0 my-auto lg:flex-row gap-tsm lg:pl-t3xl"
            >
              <>
                <RhInput
                  className="block w-full h-10 mb-tnano lg:mb-0 lg:w-2/3"
                  placeholder="Enter Email ID"
                  name="email"
                ></RhInput>
                <RhButton
                  type="submit"
                  className="w-full h-10 truncate lg:w-auto btn-bg"
                >
                  Book A Demo
                </RhButton>
              </>
            </form>
          </div>
        </div>
        <div className="absolute top-[-10%] left-[-3%] z-440 w-48 h-48 rounded-full footer-gradient-1"></div>
        <div className="absolute bottom-[-10%] hidden lg:block right-[-3%] z-44 w-48 h-48 rounded-full footer-gradient-1"></div>
        {/* <RhDivider /> */}
      </div>

      {/* lower footer */}
    </>
  );
};

export default Footer;
